import React from "react"
import { Pagination, Button, message, Modal } from "antd"
import { navigate } from "gatsby"

import { getThemeList } from "../../api/theme"
import { scrollToTop, queryParse } from "../../utils/helper"
import Layout from "../../layouts"
import ThemeCard from "../../components/ThemeCard"

import "./index.css"

const pageSize = 12
const checked = true
const order = "ASC"
const template = new Array(pageSize).fill({}).map((_, index) => ({
  id: index,
  title: "",
  url: "",
  coverUrl: "",
  qrCode: "",
}))

class ThemeList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      themes: template,
      currentPage: 0,
      total: 0,
      loading: true,
      isThemeDetailVisible: false,
      displayThemeId: ""
    }
  }

  componentWillMount() {
    // 避免构建失败
    if (typeof window === "undefined" || typeof document === 'undefined') {
      return;
    }
    this.initThemeList()
  }

  initThemeList = async () => {
    const param = queryParse()
    let { currentPage } = this.state
    if (param.page !== undefined) {
      currentPage = param.page
    }
    this.setState({ currentPage, themes: template, loading: true })
    const res = await getThemeList(pageSize, currentPage, checked, order)
    const data = res.data.data
    if (data) {
      const { themeList, pageNum } = data
      this.setState({
        themes: themeList,
        loading: false,
        total: pageNum,
        currentPage,
      })
    } else {
      message.error("加载主题界面出错")
    }
  }

  handlePagination = async page => {
    scrollToTop()
    const url = `/themes?page=${page}`
    if (typeof window !== "undefined") {
      window.history.pushState({ url: url }, "", url)
    }
    this.initThemeList()
  }

  handleAddNewTheme = () => {
    navigate("/themes/upload")
  }

  handleModalCancel = () => {
    this.setState({ isThemeDetailVisible: false })
  }

  handleThemeCardClick = (themeId) => {
    this.setState({ isThemeDetailVisible: true, displayThemeId: themeId })
  }

  render() {
    const { total, themes, currentPage, loading } = this.state
    return (
      <Layout isSpacerDisplay={true} title="主题">
        <div className="mdnice-theme-container">
          <div className="mdnice-theme-list">
            {themes.map((item, index) => (
              <ThemeCard key={index} {...item} loading={loading} onClick={this.handleThemeCardClick} />
            ))}
            <div style={{ width: 250, margin: "0em 1em" }} />
            <div style={{ width: 250, margin: "0em 1em" }} />
            <div style={{ width: 250, margin: "0em 1em" }} />
          </div>
          <div className="mdnice-theme-footer">
            <Pagination
              className="pagination"
              defaultPageSize={pageSize}
              current={currentPage ? parseInt(currentPage) : 1}
              total={total}
              onChange={this.handlePagination}
            />
            <Button type="primary" onClick={this.handleAddNewTheme}>
              提交新主题
            </Button>
          </div>
          <Modal visible={this.state.isThemeDetailVisible} footer={null} onCancel={this.handleModalCancel} bodyStyle={{ padding: "0" }}>
            <iframe style={{ width: "100%", height: "80vh" }} src={`/themes/id/${this.state.displayThemeId}`} />
          </Modal>
        </div>
      </Layout>
    )
  }
}

export default ThemeList

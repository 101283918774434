import React from "react"
import { navigate } from "gatsby"
import { Button, Skeleton, Tag } from "antd"
import { EDITOR_URL } from "gatsby-env-variables"

import {
  USER_THEME_TYPE_DESIGN,
  USER_THEME_TYPE_BUY,
  USER_THEME_TYPE_SUBSCRIBE,
} from "../utils/constant"
import "./ThemeCard.css"
import { DollarTwoTone } from "@ant-design/icons"

class ThemeCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userThemeId: props.userThemeId,
      userThemeType: props.userThemeType,
      isPublic: props.isPublic,
      price: props.price,
      checked: props.checked,
      fixThemeId: props.fixThemeId,
      isModalVisible: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      userThemeId: nextProps.userThemeId,
      userThemeType: nextProps.userThemeType,
      isPublic: nextProps.isPublic,
      price: nextProps.price,
      checked: nextProps.checked,
      fixThemeId: nextProps.fixThemeId,
    })
  }

  navigateDetailPage = () => {
    this.props.onClick(this.props.themeId)
  }

  navigateEditor = () => {
    window.open(EDITOR_URL)
  }

  handleModifyTheme = event => {
    event.stopPropagation()
    const { themeId } = this.props
    navigate(`/themes/upload?fixThemeId=${themeId}`)
  }

  handlePublishTheme = event => {
    event.stopPropagation()
    const { themeId } = this.props
    navigate(`/themes/upload?themeId=${themeId}`)
  }

  displayThemeInfo = event => {
    event.stopPropagation()
    const { themeId } = this.props
    navigate(`/themes/upload?themeId=${themeId}&readonly=true`)
  }

  handleModalCancel = () => {
    this.setState({ isModalVisible: false })
    console.log(111)
  }
  render() {
    let { name, cover, loading } = this.props
    let {
      userThemeId,
      userThemeType,
      isPublic,
      checked,
      fixThemeId,
    } = this.state

    // cover不存在
    if (!cover) {
      cover = "https://files.mdnice.com/logo.png"
    }

    let handleCardClickEvent = this.navigateDetailPage
    let tag = null
    let button = null

    // 订阅的主题
    if (userThemeId && userThemeType === USER_THEME_TYPE_SUBSCRIBE) {
      button = (
        <Button size="small" onClick={this.navigateEditor}>
          使用
        </Button>
      )
    }

    // 自己设计的主题
    if (userThemeId && userThemeType === USER_THEME_TYPE_DESIGN) {
      // 公开并审核通过的主题
      if (isPublic && checked) {
        tag = <Tag color="green">公开</Tag>
        button = (
          <Button size="small" onClick={this.handleModifyTheme}>
            修改
          </Button>
        )
      }

      // 未审核通过的修改主题
      if (isPublic && !checked && fixThemeId) {
        handleCardClickEvent = this.displayThemeInfo
        tag = <Tag color="purple">修改审核中</Tag>
      }

      // 未审核通过的发布主题
      if (isPublic && !checked && !fixThemeId) {
        handleCardClickEvent = this.displayThemeInfo
        tag = <Tag color="purple">发布审核中</Tag>
      }

      // 私有主题
      if (!isPublic) {
        handleCardClickEvent = this.displayThemeInfo
        tag = <Tag color="orange">私有</Tag>
        button = (
          <Button size="small" onClick={this.handlePublishTheme}>
            发布
          </Button>
        )
      }
    }

    // 已兑换的主题
    if (userThemeId && userThemeType === USER_THEME_TYPE_BUY) {
      tag = <DollarTwoTone style={{ marginRight: "5px" }} />
      button = (
        <Button size="small" onClick={this.navigateEditor}>
          使用
        </Button>
      )
    }

    if (!userThemeId) {
      button = (
        <Button size="small" onClick={this.navigateEditor}>
          使用
        </Button>
      )
    }

    return (
      <a className="banner-card" onClick={handleCardClickEvent}>
        <div className="banner-cover">
          {!loading ? (
            <img alt={name} src={cover} className="banner-cover-image" />
          ) : (
            <Skeleton.Avatar
              style={{ height: 200, width: "100%" }}
              size="large"
              shape="square"
              active
            />
          )}
        </div>
        <div className="banner-content">
          <div>
            {tag}
            <Skeleton loading={loading} active paragraph={false}>
              <span className="banner-content-title">{name}</span>
            </Skeleton>
          </div>
          <div>{loading ? <Skeleton.Button active /> : button}</div>
        </div>
      </a>
    )
  }
}

export default ThemeCard
